<template>
  <div class="w-full max-w-sm">
    <ClientOnly>
      <Popover class="relative py-2" @mouseleave.self="isShow = false">
        <PopoverButton @mouseover="isShow = true">
          <div class="bg-white group flex items-center border hover:border-gray-400 rounded-md px-3 py-1 text-sm font-medium focus:outline-none" v-tooltip="tooltipWarehouseName">
            <LucidePackage2 class="h-3 w-3 mr-1"/>
            <div class="leading-4 text-xs" >
              {{ onlyShowQty ? product?.selectedVariant?.qtySumForAllWarehouse : product?.selectedVariant?.primaryStockData?.quantity }}
            </div>
          </div>
        </PopoverButton>

        <transition
            enter-active-class="transition ease-out duration-100" enter-from-class="opacity-0 -translate-y-1"
            enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-50"
            leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 -translate-y-1"
        >
          <OnClickOutside @trigger="isShow = false" v-if="isShow">
            <PopoverPanel class="absolute left-1/2 z-300 mt-2 w-screen max-w-xs -translate-x-1/2 transform px-4 sm:px-0 shadow-lg bg-white/90 backdrop-blur rounded-md" static>
              <div class="absolute bottom-full md:left-1/2 md:-translate-x-1/2 overflow-hidden inline-block">
                <div class="size-3 bg-white border rotate-45 transform origin-bottom-left"></div>
              </div>

              <RadioGroup v-model="product.selectedVariant.primaryStockData" :disabled="onlyShowQty" by="warehouseId">
                <RadioGroupLabel class="sr-only">{{ $t('widgets.warehouse.other-warehouse-qty') }}</RadioGroupLabel>
                <div class="relative -space-y-px rounded-md">
                  <RadioGroupOption as="template" v-for="(qty, qtyIdx) in product.selectedVariant.stockData" :key="qty.warehouseId" :value="qty" v-slot="{ checked, active }" :disabled="qty.quantity === 0">
                    <div
                        :class="[qtyIdx === 0 && !onlyShowQty ? 'rounded-tl-md rounded-tr-md' : '', qtyIdx === product.selectedVariant.stockData.length - 1 && !onlyShowQty ? 'rounded-bl-md rounded-br-md' : '', checked && !onlyShowQty ? 'bg-gray-50 border-primary-200 z-10' : 'border-gray-200', 'relative border p-2.5 flex flex-col md:pl-4 md:pr-6 md:grid md:grid-cols-2 focus:outline-none', {'!cursor-not-allowed	' : qty.quantity === 0 && !onlyShowQty}, {'cursor-pointer' : !onlyShowQty}]">
                    <span class="flex items-center text-sm">
                      <span
                          :class="[checked ? 'bg-primary-600 border-transparent' : 'bg-white border-gray-300', active ? 'ring-none' : '', 'h-4 w-4 rounded-full border flex items-center justify-center']"
                          aria-hidden="true" v-if="!onlyShowQty">
                        <span class="rounded-full bg-white w-1.5 h-1.5"/>
                      </span>
                      <RadioGroupLabel as="div" :class="[checked ? 'text-primary-900' : 'text-gray-900', 'ml-3 font-medium']">
                        {{ qty.warehouseName }}
                      </RadioGroupLabel>
                    </span>
                      <RadioGroupDescription as="div" class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right">
                        <span :class="[checked ? 'text-primary-900' : 'text-gray-900', 'font-medium']" v-if="qty.quantity">{{qty.quantity }} {{ product.selectedVariant?.unitName }}</span>
                        <span :class="['text-red-500', 'font-medium']" v-else>{{ $t('listing.stock-out') }}</span>
                      </RadioGroupDescription>
                    </div>
                  </RadioGroupOption>
                </div>
              </RadioGroup>

            </PopoverPanel>
          </OnClickOutside>
        </transition>
      </Popover>
    </ClientOnly>
  </div>
</template>

<script setup>
import {Popover, PopoverButton, PopoverPanel} from "@headlessui/vue";
import {RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption} from '@headlessui/vue'
import {ArchiveBoxIcon} from "@heroicons/vue/24/outline";
import {OnClickOutside} from "@vueuse/components";

const props = defineProps({
  product : Object|Array,
  variantId : Number,
  onlyShowQty : Boolean
});

const {user, isWholesaleUser} = await useCustomer();
const {isB2BAccount} = useHelper()

const isShow = useState(`isShow${props.variantId}`, () => false);

const tooltipWarehouseName = computed(() => {
  if (props.onlyShowQty) return null

  return props.product?.selectedVariant?.primaryStockData?.warehouseName
})

</script>
